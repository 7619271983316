$break-small: 600px;
$break-medium: 1000px;

img.searchIcon {
    width: 25px;
}

.searchBar {

    div[class*='col-'] {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .searchBarRow {
        display: flex;
        // padding-bottom: 1rem;

        .applyBorderBottom {
            padding: 0rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }

        .applyBorderBottom.img {
            text-align: right;
        }

        .searchText,
        .searchText:hover,
        .searchText:focus {
            background: transparent;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            color: #fff !important;

            @media screen and (max-width: $break-small) {
                display: none;
            }
        }

        @media screen and (max-width: $break-small) {
            display: none;
        }
    }

    .searchBarRowSmall {
        display: none;

        @media screen and (max-width: $break-small) {
            display: block;
            text-align: left;
            // position: absolute;
            right: 20px;

            .searchIconContainer{
                height: 4rem;
            }

            img.searchIcon {
                width: 25px;
                position: absolute;
                right: 0px;
                bottom: 12px;
            }
        }
    }

    @media screen and (min-width: $break-small) {
       // position: absolute;
        width: 100%;
        bottom: 10px;

        img.searchIcon {
            width: 25px;
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }

}

.searchBoxHidden.collapsing {
    display: none !important;
    height: 0px !important;
}

.searchBoxHidden.collapse {
    display: none;

    @media screen and (max-width: $break-small) {
        display: block;
        position: fixed;
        width: 100%;
        top: 4rem;
        height: 90vh;
        left: 0px;
        padding: 0rem;
        z-index: 999;

        .applyPadding {
            padding: 1rem;
            background: #101820;
        }

        .applyBorderBottom {
            padding: 0.75rem 0rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }

        .applyBorderBottom.img {
            text-align: right;
        }

        .wrapperSection{
            height:40rem;
            background: rgba(0, 0, 0, 0.8);
        }

        .searchText,
        .searchText:hover,
        .searchText:focus {
            width: 100%;
            background: transparent;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            color: #fff !important;
        }
    }
}

/* Fix for searchbox toggle in mobile in production build (Already works on localhost) */
.searchBoxHidden.collapse:not(.show) {
    display: none !important;

    @media screen and (max-width: $break-small) {
        display: none !important;
    }
}

.person-search{
    @media screen and (max-width: $break-small) {
    // padding-top: 15px;
    padding-right: 10px;
    cursor: pointer;
    margin-top: 25px;
    visibility: hidden;
    }
}

.person-eid{
    @media screen and (max-width: $break-small) {
        bottom: unset !important;
        width: 45px !important;
        padding-top: 15px !important;
    }
}