@import './styles/mixin';

$background-color:#000000;
$font-family:'29LTBukra';

html {
    @include customScrollBarWhite;
    padding: 0 !important;
    margin: 0 !important;
}

body {
    background-color: $background-color !important;
    font-family: $font-family !important;
    font-weight: normal !important;
    scroll-behavior: smooth;

    *:focus {
        outline: 0 !important;
    }

    .loadingMessage {
        color: #fff;
        padding-top: 1rem;
    }

    a:link,
    a:hover {
        text-decoration: none !important;
        cursor: pointer;
    }

    .loadingMessage {
        text-align: center;
        padding-top: 1rem;
    }

    .row {
        margin: 0px;
    }

    .not-avaialble-text,
    .validationMessage {
        padding: 1rem 2rem;
        text-align: center;
        color: #A4DBE8;
    }

    .scrollableSection {
        @media (min-width : 601px) {
            height: 70vh;
            overflow-y: auto;
            @include customScrollBar;
        }
    }
    .user-type-message{
        padding-top: 1em;
        color: white;
        text-align: justify;
        display: flex;
        justify-content: center;
    }
}