$break-small: 600px;
$break-medium: 1024px;

.orgLogo {

    img {
        width: 60px;
        padding-top: 0.5rem;
        cursor: pointer;

        @media screen and (max-width: $break-small) {
            width: 55px;
        }
    }

    @media screen and (max-width: 600px) {
        position: absolute;
        width: 100%;
        left: 45%;
        bottom: 12px;
    }

    @media (min-width: 600px) and (max-width: 1000px) {
        position: absolute;
        width: 100%;
        bottom: 10px;
        left: 60%;
    }

    @media screen and (min-width: 1000px) {
        position: absolute;
        width: 100%;
        bottom: 10px;
        left: 60%;
    }

}