@import './variables';
@import './extend';

@mixin customScrollBar {

    @media (min-width : 601px) {

        & {
            padding-right: 1rem !important;
            padding-bottom: 1rem !important;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }

        &::-webkit-scrollbar {
            width: 5px;
            height: 10px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 1rem;
            background: rgba(255, 255, 255, 0.1);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 1rem;
            background: rgba(255, 255, 255, 0.2);
        }

        &::-webkit-scrollbar-thumb:hover,
        &::-webkit-scrollbar-thumb:active {
            background: rgba(255, 255, 255, 0.25);
        }
    }
}

@mixin customScrollBarWhite {

    &{
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 1rem;
        background: rgba(255, 255, 255, 0.2);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover,
    &::-webkit-scrollbar-thumb:active {
        background: rgba(255, 255, 255, 0.7);
    }
}

@mixin buttonPrimary {
    & {
        @extend %button;
        border: none;
        background: #da281c;
    }
}

@mixin buttonSecondary {
    & {
        @extend %button;
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: transparent;
    }
}

@mixin label {
    & {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        padding-right: 3px;
        padding-bottom: 5px;

        @media (max-width: $bp-sm) {
            font-size: 10px;
        }

        .required {
            color: #da281c;
            font-weight: bold;
            padding-left: 3px;
        }
    }
}

@mixin userInput {
    & {
        select {
            padding: 0.5rem;
            width: 98%;
            font-size: 16px;
            height: 48px;
            background: $input-bg;
            border: none;
            border-radius: 8px;

            option {
                background: inherit;
                color: white;
                padding: 0.5rem;
            }
        }

        input {
            padding: 0.5rem;
            width: 100%;
            font-size: 16px;
            height: 48px;
            background: $input-bg;
            border: none;
            border-radius: 8px;
        }

        .btn-group {
            width: 100%;
            height: 48px;

            .switch {
                background: #1E2124 0% 0% no-repeat padding-box !important;
                border: transparent !important;
                box-shadow: none !important;
                font-size: 12px;
                height: 48px;

                &.active {
                    background-color: #da281c !important;
                    border-color: transparent !important;
                }
            }
        }
    }
}

@mixin userInputControl {

    & {
        margin: 0rem 0rem 1rem 0rem;
        background: #1E2124;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        color: white;
        @include userInput;

        @media (max-width: $bp-sm) {
            font-size: 12px;
        }
    }
}

@mixin responsive($bp) {

    @if $bp==hg {
        @media (min-width: $bp-lg) {
            @content;
        }
    }

    @if $bp==lg {
        @media (max-width: $bp-lg) {
            @content;
        }
    }

    @if $bp==lg-md {
        @media (max-width: $bp-lg-md) {
            @content;
        }
    }

    @if $bp==md {
        @media (max-width: $bp-md) {
            @content;
        }
    }

    @if $bp==sm {
        @media (max-width: $bp-sm) {
            @content;
        }
    }

    @if $bp==tn {
        @media (max-width: $bp-tn) {
            @content;
        }
    }
}