$input-color: white;
$input-box-color: gray;
$input-background-color: transparent;

.input-email{
    color: $input-color !important;
    border: none !important;
    border-bottom: 0.125em solid $input-box-color !important;
    background: $input-background-color !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

::placeholder {
    font-family: '29LTBukra';
}
