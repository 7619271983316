$label-email-color: white;
$label-generic-color: #A4DBE8;
$label-font-size: 1em;

.label-email{
    color: $label-email-color !important;
    font-size: 0.75*$label-font-size !important;
    margin-bottom: 0em;
    font-family: '29LTBukra';
}

.label-generic{
    color: $label-generic-color !important;
    font-size:1.5*$label-font-size !important;
    font-weight: bold;
    padding: 1em 0;
}