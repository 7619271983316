.loginContainer {
    .container-fluid {
        padding: 0px;
    }

    .imageHolder {
        padding: 0px;
    }

    .login-form {
        padding: 3rem;
        margin: auto;
    }

    .emaiText {
        outline: none !important;
    }

    .cache-clear{
        float:right;
        margin-top: 10px;
        border: none;
        transition: all ease-in-out 0.1s;
        cursor: pointer;
        background: none;
        text-decoration: underline;
        font-size: 12px;
        color: #a4dbe8;
    }
    .btn-danger {
        height: 48px;
        width: 76.64px;
        color: #fff;
        background-color: #DA281C;
        border: 0px !important;
    }

    button.btn.btn-danger.btn-md {
        background-color: #DA281C;
        width: 76.64px;
        height: 48px;
    }

    .btn-danger:disabled {
        color: #fff;
        height: 48px;
        background-color: rgba(255, 255, 255, 0.3) !important;
        width: 76.64px;
    }

    .desktopBg {
        background-image: url('/media/loginBg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 60%;
        /*updated from center*/
        height: 100vh;
        margin: 0;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;

        .desktopLogo {
            width: fit-content;
            height: 100vh;
            display: flex;
            margin: auto;

            img{
                width: 450px;
                margin: auto;
            }
        }
    }

    .mobileBg {
        background-image: url('/media/loginBg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 60vh;
        margin: 0;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;

        .mobileLogo {
            width: fit-content;
            height: 60vh;
            display: flex;
            margin: auto;
            img{
                width: 350px;
                margin: auto;
            }
        }
    }
}

@media only screen and (max-width: 600px) {

    .loginContainer {

        div[class^='col-'] {
            padding-right: 0px !important;
            padding-left: 0px !important;
        }

        .login-form {
            padding: 0rem 2rem;
        }

        .buttonHolder {
            padding: 1rem 0rem;
            text-align: right;
        }
    }

}

// Tab or iPad

@media (min-width: 601px) and (max-width:1024px) {
    .loginContainer {
        .mobileBg {
            height: 65vh;

            .mobileLogo {
                img{
                    width: 300px;
                }
            }
        }

        .buttonHolder {
            text-align: right;
            padding: 2rem 1rem;
        }
    }
}

@media (min-width: 601px) and (max-width:1024px) and (orientation: landscape) {
    .loginContainer {
        .login-form {
            padding: 3rem 1rem
        }
    }
}

@media (min-width:1024px) and (max-width:1366px) and (orientation: portrait) {
    .loginContainer {
        .login-form {
            padding: 3rem 1rem
        }
    }
}

@media (min-width: 1025px) {
    .loginContainer {
        .input-email {
            height: 100%
        }

        .buttonHolder {
            text-align: right;
            padding: 2rem 1rem;
        }
    }
}