$break-small: 600px;
$hamburger-padding: 1.5rem 1rem;

// NOTE: To see default styles for hamburger, see https://github.com/negomi/react-burger-menu#styling

/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 25px;
    left: 25px;
    bottom: 15px;

    @media screen and (max-width: $break-small) {
        left: 20px;
        bottom: 12px;
    }
}

/* Color/shape of burger icon bars #373a47 */
.bm-burger-bars {
    background: #fff !important;
    height: 10% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #fff !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 75px !important;
    width: 400px !important;
    left: 0px !important;
    top: 0px !important;
    box-shadow: 0px 15px 10px -15px #111;
    background: transparent;
    top:14px !important;
    z-index: 9;

    >span:first-child {
        position: absolute;
        top: 30px !important;
        left: 2.5rem !important;
    }
}



/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
    height: 35px !important;

}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 100% !important;
    background: rgba(0, 0, 0, 0.7);
    left: 0;
    z-index: 9999 !important;
}

/* General sidebar styles */
.bm-menu {
    background: #101820;
    padding: 4.5em 0em 0em 0em;
    font-size: 1.15em;
    width: 400px;

    a,
    a:hover,
    a:active {
        color: #fff;
        padding: 1rem 0rem 1rem 2rem;
        text-decoration: none !important;
        outline: none !important;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    a.active {
        background: #da281c;
    }

    @media screen and (max-width: $break-small) {
        width: 80%;

        a,
        a:hover,
        a:active {
            padding: 0.75rem 0rem 0.75rem 2rem;
        }
    }

    @media (min-width: 601px) and (max-width:1024px) {

        a,
        a:hover,
        a:active {
            padding: 1.5rem 0rem 1.5rem 2rem;
        }
    }

    @media (min-width: 601px) and (max-width:1024px) and (orientation: landscape) {}
}

/* Scroll bar css start */

.bm-menu{
    -ms-overflow-style: -ms-autohiding-scrollbar;
   
    @media (min-width: 1024px) and (orientation: landscape) {
       width: 350px;
        padding-top: 0;
        z-index: 10;
        position: absolute;
    }
    @media (max-width: 600px){
        width: 300px;
         padding-top: 0;
         z-index: 10;
         position: absolute;
     }
}

.bm-menu::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.bm-menu::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

.bm-menu::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border: 0px none #fff;
    border-radius: 3px;
}

.bm-menu::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.1);
}

.bm-menu::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 0.1);
}

.bm-menu::-webkit-scrollbar-track {
    background: rgba(138, 137, 134, 0.002);
    border: 0px none #fff;
    border-radius: 0;
}

.bm-menu::-webkit-scrollbar-track:hover {
    background: rgba(138, 137, 134, 0.002);
}

.bm-menu::-webkit-scrollbar-track:active {
    background: rgba(138, 137, 134, 0.002);
}

.bm-menu::-webkit-scrollbar-corner {
    background: transparent;
}

/* Scroll bar css end */



/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0em 0em 0em 0em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.hamburger {

    img.toggleIcon {
        width: 33px;
    }

    @media screen and (max-width: $break-small) {
        width: 30px;
    }

    .hideOnSmall {
        @media (max-width: $break-small) {
            display: none;
        }
    }

    .hideOnLarge {
        @media not all and (max-width: $break-small) {
            display: none;
        }
    }
}

@media screen and (max-width: $break-small) {
    .bm-burger-bars {
        width: 30px !important;
    }

    .bm-cross-button {
        width: 90% !important;

        .bm-cross {
            height: 25px !important;
        }
    }
}