.errorBoundary {
    .errorMessage {
        text-align: center;
        color: #fff;
        font-size: 16px;
        padding: 10rem 2rem 0rem 2rem;
    }

    .pathName{
        opacity: 0.3;
        color:#fff;
        width:100%;
        text-align: center;
    }

    .buttonContainer {
        padding: 3rem 0rem !important;
        margin: 0rem !important;
        width:100%;
        text-align: center;

        button {
            background: #DA281C 0% 0% no-repeat padding-box;
            border-radius: 8px;
            opacity: 1;
            width: 284px;
            height: 48px;
            text-align: center;
            border: transparent;
            color: #ffffff;
        }
    }
}