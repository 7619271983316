$break-small: 600px;
$header-padding: 0.5rem;

.header {
    width: 100%;
    height: 12vh;
    background: #101820;
    color: #fff;
    padding-bottom: 0.5rem;
    box-shadow: 0px 2px 5px black;

    .fixedHeader {
        position: fixed;
        background: #101820;
        width: 100%;
        height: 12vh;
        z-index: 9999;

        .rowHeader {
            margin-right: 0px;
            height: 12vh;
        }
    }

    .fixedHeader.applyShadow {
        box-shadow: none;
    }

    .geantLogo {
        position: absolute;
        bottom: 10px;
        left: -15px;
    }

    .geantSearch {
        cursor: pointer;
        position: absolute;
        right: 25px;
        bottom: 10px;
    }

    .backIcon{
        cursor: pointer;
        position: absolute;
        left: 15px;
        bottom: 10px;
    }

    .pageTitle {
        position: absolute;
        bottom: 10px;
        font-size: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0.7rem;
    }

}

@media (max-width: $break-small) {
    .header {
        height: 4rem;

        .fixedHeader {
            height: 4rem;
            box-shadow: 0px 5px 10px #00000080;

            .rowHeader {
                height: 4rem;
            }
        }

        .pageTitle {
            font-size: 18px;
            left: 5px;
        }

        .geantLogo {
            position: absolute;
            bottom: 10px;
            left: 5px;
        }

        .geantSearch {
            position: absolute;
            right: 15px;
            bottom: 10px;
        }
    }
}

@media (min-width: 601px) and (max-width:1024px) {
    .header {
        height: 8vh;

        .fixedHeader {
            height: 8vh;

            .rowHeader {
                height: 8vh;
            }
        }
    }
}

@media (min-width: 601px) and (max-width:1024px) and (orientation: landscape) {

    .header {
        height: 10vh;

        .fixedHeader {
            height: 10vh;

            .rowHeader {
                height: 10vh;
            }
        }
    }

}

@media (min-width:1024px) and (max-width:1366px) and (orientation: portrait) {
    .header {
        height: 6vh;

        .fixedHeader {
            height: 6vh;

            .rowHeader {
                height: 6vh;
            }
        }
    }
}

@media (min-width:1025px) and (max-width:1366px) and (orientation: landscape) {

    .header {
        height: 9vh;

        .fixedHeader {
            height: 9vh;

            .rowHeader {
                height: 9vh;
            }
        }

        .pageTitle {
            left: -3%;
        }
    }
}

@media (min-width:1025px) {
    .app.window {
        .header {
            width: 100%;
            height: 12vh;
            background: #101820;
            color: #fff;
            padding-bottom: 0.5rem;

            .fixedHeader {
                position: fixed;
                background: #101820;
                width: 100%;
                height: 12vh;
                z-index: 9999;

                .rowHeader {
                    margin-right: 0px;
                    height: 12vh;
                }
            }

            .pageTitle {
                left: -5%;
            }
        }
    }

}

.eidModal {
    // width: fit-content;
    @media screen and (max-width: $break-small) {
     margin-left:  1em !important;
     margin-right: 1em !important;
    }
     margin-top: 10em !important;
    //  max-width: fit-content !important;
     .modal-content{
         .modal-header{
             padding-bottom: 0;
         }
         .modal-body{
             font-size: 10px;
             padding-bottom: 0;
             padding-left: 0;
             margin: 0 auto !important;

             .discount-id-block{
              text-align: center;
              padding: 20px 0px;
              .material-icons {
                font-size: 50px !important;
                margin-bottom: 15px;
              }
             }
         }
         .modal-footer{
             padding-top: 0;
             padding-bottom: 0;
         }
     }
 }


 .header-sec-block{
   display: flex;
   @media screen and (min-width: $break-small) {
    //margin-top: 60px;
    height: 100%;
    align-items: end;
    }
 }

.eid-block{
  padding-top: 15px;
  padding-left: 0;
  @media screen and (max-width: $break-small) {
    padding-top: 0;
  }
  .person-eid{
    width: 42px;
    @media screen and (max-width: $break-small) {
      padding-top: 18px;
    }
  }
 }

 .material-id-icon{
  font-size: 30px;
  cursor: pointer;
  @media screen and (max-width: $break-small) {
  font-size: 24px;
  // padding-top: 15px;
  padding-right: 10px;
  cursor: pointer;
  margin-top: 25px;
  }
}

.cardContainer {

    margin: 1rem auto;
  
    .employee-id-background {
      background: url(/assets/backgrounds/id_back.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: inherit;
      border-radius: 25px;
      padding: 0rem 1rem 1rem 1rem;
      min-height: max-content;
      width:  max-content;
      // width: 270px;
  
      .employee-id-block {
        padding-left: 5vh;
  
        .employee-id-img-block {
          margin-top: 4vh;
          margin-bottom: 2vh;
          display: inline-flex;
          width: 100%;
  
          .left {
            // width: 150px;
          }
  
          .right {
            // width: 150px;
            text-align: right;
          }
  
          .employee-id-photo {
            padding: 1vh;
            width: 120px;
            border-radius: 25px;
          }
  
          .employee-id-logo {
            width: 110px;
          }
  
          .employee-id-text-block {
            color: black;
          }
        }
  
        .digital-card-number {
          border-top: 1px solid gray;
          padding-top: 0.5rem;
          max-width: 225px;
          text-align: center;
          font-weight: bold;
          color: black;
          position: absolute;
          bottom: 10px;
          width: 170px;
          margin: auto;
        }
      }
  
      div.digital-card-name {
        font-size: 16px;
        font-weight: bold;
        color: black;
      }
  
      div.digital-card-department {
        font-size: 10px;
        color: black;
      }
  
      div.digital-card-department.department {
        margin-bottom: 1rem;
      }
  
      div.digital-card-detail {
        font-size: 10px;
        color: gray;
        margin-top: 5%;
  
        img {
          padding-right: 5px;
          -webkit-filter: invert(100%);
          filter: invert(100%);
        }
        .digital-exec{
          color: #DA281C !important;
          font-size: 14px;
        }
      }
    }
  }
  
  @media(max-width:600px) {
  
    .cardContainer {
      .employee-id-background {
        width: max-content;
        margin: 0rem 0.75rem;
      }
    }
    .eidModal.mobile-popupeid-available{
      margin: 0.5rem auto !important;
    }
  }
  
  @media (min-width: 601px) and (max-width:1024px) {
    .cardContainer {
      .employee-id-background {
  
        .employee-id-text-block {
          zoom: 1.5;
        }
  
        .employee-id-block {
          .digital-card-number {
            max-width: none;
            width: 250px;
          }
        }
      }
    }
  }
  
  @media (min-width: 601px) and (max-width:1024px) and (orientation: landscape) {
    .cardContainer {
      flex: 0 0 40%;
      max-width: 40%;
  
      .employee-id-background {
        .employee-id-text-block {
          zoom: 1.1;
        }
      }
    }
  }
  
  @media (min-width:1024px) and (max-width:1366px) and (orientation: portrait) {
    .cardContainer {
      flex: 0 0 60%;
      max-width: 60%;
  
      .employee-id-background {
        min-height: max-content;
        width:  max-content;
      }
    }
  }
  
  @media (min-width:1025px) and (max-width:1366px) and (orientation: landscape) {
    .app.device {
      .cardContainer {
        flex: 0 0 30%;
        max-width: 30%;
  
        .employee-id-background {
          min-height: max-content;
          width:  max-content;
        }
      }
    }
  }