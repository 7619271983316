$break-medium: 1100px;
$break-small: 600px;

.appMenuLinks {

    .section2{
        box-shadow: 0px -15px 10px -15px #111;
    }

    .menu-item {
        display: block;

        .linkIcon {
            width: 30px !important;

            @media screen and (max-width: $break-small) {
                width: 25px !important;
            }

        }

        .linkName {
            padding-left: 2rem;

            @media screen and (max-width: $break-small) {
                font-size: 15px;
            }
        }
    }

    .quickLinks {
        padding-top: 1rem;

        @media screen and (max-width:$break-medium) {
            padding-top: 3rem;
        }

        @media screen and (max-width:$break-small) {
            padding-top: 1rem;
        }

        .linkName {
            span {
                padding-bottom: 0.5rem;
                color: #fff;
                border-bottom: 2px solid #da281c;
            }
        }
    }

    div.menu-item {
        padding: 0.5rem 0rem 1rem 2rem;
        display: flex;
        flex-wrap: wrap;
    }

    div.appContainer {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem 0rem 2rem 4rem;

        .appIcon {
            padding: 0rem 1rem 1.5rem 0.75rem;
            .more-links{
                cursor: pointer;
                color: #A4DBE8;
                font-size: 14px;
            }
            .link-names{
                padding-left:1rem;
                font-size:14px;
            }
            .externalLink {
                //padding: 1rem 0rem;
                cursor: pointer;
                span {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 20px;
                    color: #A4DBE8;
                }
                .iconz{
                    padding-left:5px;
                    font-size:40px;
                    @media screen and (max-width:$break-small) {
                        font-size:30px;
                    }
                }
                a{
                    font-size:14px;
                }
                .link-names{
                    padding-left:1rem;
                    font-size:14px;
                }
            }
            .rotate-icon {
                padding-left: 15px;
                padding-top: 10px;
                width: 20px;
                height: 20px;
                position: inherit;
                background: transparent;
                border: navajowhite;
              }

            @media screen and (max-width: $break-small) {
                padding: 0rem 1rem 1rem 0.75rem;
            }

            a {
                padding: 0rem 0rem 0rem 0.5rem;
            }

            .linkIcon {
                width: 40px !important;

                @media screen and (max-width: $break-small) {
                    width: 30px !important;
                }
            }
        }
        .infoPopup{
            .modal-content{
                background: #101820;
                .modal-header{
                    background: #101820;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    //border-radius: 10px;
                }
                .modal-body{
                    display:flex;
                    flex-wrap:wrap;
                    width:100%;
                    background: #101820;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    //border-radius: 10px;
                    .close-btn-wrapper{
                        width:100%;
                        text-align:center;
                        button{
                            width: 20%;
                            border-radius: 10px;
                            background-color: #da281c;
                            border: none;
                            color: #fff;
                            font-size: 14px;
                            padding: 10px;
                            /* font-weight: 700; */
                        
                        }
                    }
                }
            }
        }
    }
}

@keyframes animateArrow {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }

    40% {
        transform: translateX(-1px);
    }

    60% {
        transform: translateX(-0.5px);
    }
}

.clear-cache-link{
    padding: 0.75rem 0 0.75rem 2rem;
    border: unset;
    width: 100%;
    text-align: left;
    background: unset;
    color: #A4DBE8;
    .linkicon{
        margin-left: 5px;
    }
    .linkName{
        margin-left: 0.4rem;
    }
}

/* Scroll bar css start */



/* Scroll bar css end */

/* @media screen and (max-width: $break-small) {

    .menu-item {
        .linkIcon {
            width: 25px !important;
        }

        .linkName {
            font-size: 15px;
        }
    }

    div.appContainer {
        .appIcon {
            padding: 0rem 1rem 1rem 0.75rem;

            .linkIcon {
                width: 50px !important;
            }
        }
    }
}

@media (min-width: 601px) and (max-width:1024px) {
    .quickLinks {
        padding-top: 3rem;
    }
}

@media (min-width: 601px) and (max-width:1024px) and (orientation: landscape) {} */